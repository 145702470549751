.order-history-page {
    min-height: 80vh;
}

.order-history-page .pageHeading {
    margin-top: 15px;
}

.order-history-loader {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.history-page-icon-wrapper {
    margin: 25px auto;
    background-color: #d0d0d0;
    border: none;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding: 20px;
}

.history-table {
    min-height: 95vh;
    border-right: 0.5px solid  #d0d0d0;
    border-left: 0.5px solid  #d0d0d0;
}

.history-table.loading .filter-bar .menu-items {
    pointer-events: none;
}

.history-table .cell-data-wrap {
    padding: .75rem 1rem !important;
}

.history-page-icon-wrapper img {
    width: 40px;
    height: 40px;
    margin-left: -2px;
}

.history-page-heading {
    text-align: center;
}

.history-page-heading h5 {
    color: #000 !important;
}

.order-history-table {
    position: relative;
}

.order-history-table .filter-icon-wrapper {
    position: absolute;
    right: 40px;
    top: 20px;
    color: #000;
    z-index: 3;
}

.ui.popup.menu.filter-icon-wrapper {
    min-width: 400px;
}

.reset-link {
    font-size: 14px;
    margin: 10px 0 15px !important;
    color: #707070;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.filter-bar .menu-items .filter-panel .filter-item.filter-checkbox {
    padding-bottom: 5px;
}

.filter-checkbox .ui.checkbox input~label {
    font-size: 16px !important;
    color: #404040 !important;
}

.order-history-table p.field-section {
    font-size: 14px;
    color: #000 !important;
    margin-bottom: 15px;
    font-weight: 700;
}

.filter-order-range .ui.single-input.has-icon.align-right .input-icon {
    right: 15px;
    color: #000;
}

.invoice-link {
    margin-left: 15px;
    cursor: pointer;
}

.order-history-table .global-filter .input-box {
    width: 250px;
}

.order-history-table .ui.date-picker-input.open-up .calendar-overlay {
    transform: translateY(95%);
    bottom: 0;
}

.order-history-table .filter-bar .menu-items .filter-panel.show {
    overflow-x: scroll;
    z-index: 999;
    width: 675px;
}

.not-applicable-data {
    color: #a0a0a0;
    font-style: italic;
}
