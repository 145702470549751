.user-info-header .secondaryNav {
    padding-bottom: 0;
}

.user-info-header .secondnav .secondnavLink {
    padding-bottom: 15px;
}

.user-info-header .secondnav .secondnavLink.active {
    border-bottom: 3px solid #1274B7;
}

.cart-popup-icon-left-space {
    margin-left:1.5rem !important;
}