.pull-right{float: right}
.smallBox{height: 40px;	width: 150px;	border: 1px solid #D0D0D0;
    padding:9px 20px;	background-color: #F0F0F0;font-size: 14px;	font-weight: bold;	line-height: 22px;
    margin-right: 10px;
    margin-bottom: 10px;}
    .taxDetail .scuf-col{padding-bottom: 0px;}
    .taxDetail  p{float: right}
    .extendedPrice {font-size: 12px;}
    .datealign{
        padding-top: 0px !important;
        padding-right: 15px !important;
        padding-left: 0px !important;
    }
    .rowalign{
        padding-left:0px !important;
        padding-right:15px !important;
    }
    .rowalign > .input-box{
        height:72px !important;
    }

    .rowalign > .input-box > .input-wrap > .input-box{
        height:44px !important;
        width:130px !important;
    }

    .addrowalign{
        padding-left:0px !important;
        padding-right:15px !important;
        padding-top:0px !important;
    }
    .msa-configure-loader{
        left: 50% !important;
        white-space: nowrap;
    }
    .serialNum > .input-wrap > .input-box{
        width : 150px;
    }

    .ui.scuf-dropdown-wrapper>.ui.selection.dropdown>.text {
        margin: -2px 0px 0px 0px !important;
    }

    .configure-apu-modal .ui.scuf-dropdown-wrapper .ui.selection.dropdown {
        border: 0px !important;
        background: none;
    }
    .configure-apu-modal .ui.scuf-dropdown-wrapper.expanded .ui.active.visible.selection.dropdown {
        border: .0625rem solid #1274B7 !important;
    }
    .configure-apu-modal .ui.forge-datatable-wrap .p-datatable tbody.p-datatable-tbody>tr>td .cell-data-wrap {
        padding: 0 0 0 1.5rem;
    }

    .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap .p-datatable .p-datatable-header .filter-bar .header-bar-item{
        padding: 0 1.25rem;
    }


    .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap .p-datatable .p-datatable-header .select-all-checkbox {
        padding-left: 1rem;
    }
    .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap .p-datatable .p-selection-column .p-checkbox {
            left: 1rem;
    }

    .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap .ui.checkbox label:before {
        width: 1.25rem;
        height: 1.25rem;
    }

    .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap .ui.checkbox label:after {
        width: 1.25rem;
        height: 1.25rem;
    }

    .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap .p-datatable .p-selection-column .p-checkbox-box {
        width: 1.25rem;
        height: 1.25rem;
    }

    .configure-apu-modal .ui.forge-datatable-wrap .ui.scuf-dropdown-wrapper>.ui.selection.dropdown{
        padding: .688rem .75rem .688rem 0;
    }
.msa-add-serial-confirm-checkbox {
    padding: 0;

}
.configure-apu-modal-content .msa-add-serial-confirm-checkbox .accept-terms-check {
    margin-top: 10px ;
}

.configure-apu-modal-content .msa-add-serial-confirm-checkbox .ui.checkbox label:before {
   height: 1.25rem;
   width: 1.25rem;
}

.configure-apu-modal-content .msa-add-serial-confirm-checkbox .ui.checkbox label::after {
    height: 1.25rem;
    width: 1.25rem;
 }

 .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap.actionbar.header-bar .p-datatable-header{
    height: 3rem !important;
 }
 .configure-apu-modal .add-serial-no .ui.forge-datatable-wrap.actionbar.header-bar .p-datatable-header .select-all-checkbox {
    top: 3.75rem;
 }
