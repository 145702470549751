/************secondary navbar styles ******************/
.fullwidthRow {
    margin: 0px !important;
    padding: 0px !important;
}

.secondaryNav {
    background: #ffffff;
    padding: 10px 0px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px;
}

.secondaryNav .scuf-col {
    padding: 0px 12px !important;
    line-height: 30px;
}

.secondaryNav p,
.secondaryNav a {
    float: right;
}


.ui.scuf-dropdown-wrapper {
    width: 100%;
}

.ui.checkbox input~label,
.ui.checkbox input~label:hover {
    color: #404040 !important;
    font-size: 12px !important;
}


/********************Checkout table styles****************************/


.checkboxLabel label,
.ui.checkbox input~label,
.ui.checkbox input~label:hover {
    font-size: 10px !important;
    font-weight: 500;
    color: #1274B7 !important;
}
.cart-product-name {
    line-height: 1.5;
    font-weight: 900;
    text-transform: uppercase;
    color: #404040;
}
.cart-gridcol{
    padding-right: 0px;
    padding-left: 0px
}

.fullWidthButton {
    width: 100%;
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
    font-size: 14px !important;
}

.overlay-position-wrap {
    width: 64%
}

/************************general page styles*******************/
.helpText {
    color: #808080;
    font-size: 12px;
}

.paddingZero {
    padding: 0px !important
}

.centerAlign {
    text-align: center
}

.pull-right {
    float: right
}

.pageHeading {
    font-weight: 300;
    text-align: center;
    color: #101010;
}


h3 {
    font-size: 18px;
}

.paddingbottom {
    padding-bottom: 0px !important
}

.plainTable {
    border: none !important;
}

.plainTable tbody::before{
    line-height:1em;
    content:"@";
    display:block;
    text-indent:-99999px;
}

.ui.striped.table.plainTable tbody tr:nth-child(2n) {
    background: #fff
}

.ui.table.plainTable tr td:first-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 2.14;
    color: #808080;
}

.ui.table.plainTable tr td:last-child,
.ui.table.plainTable tr td,
.ui.table.plainTable tfoot tr th {
    font-size: 14px;
    font-weight: 500;
    line-height: 2.14;
    color: #303030;
}

.smallText span {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: right;
    color: #808080;
    display: block;
}

.smallText b {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.1px;
    text-align: right;
    color: #606060;
}

.capitalText {
    font-size: 11px;
    font-weight: 800;
    text-transform: uppercase;
    color: #808080
}

.cardMarginBottom {
    margin-bottom: 20px !important;
}


.ui.card.cardMarginBottom  .content.cartListContent{
    padding: 20px;
}
.smallText .h-icon {
    margin-right: 5px;
    /* margin-left: 18px; */
}

.cardContent {
    padding: 20px 0px 0px 0px !important
}

.detailsTable tr td:first-child {
    text-align: right;
    padding: 0.5rem 2rem 0.5rem 0rem;
    text-transform: uppercase;
    font-size: 11px !important;
    font-weight: 800 !important;
}

.detailsTable tr td:last-child,
.ui.list.scuf-list-wrapper .content {
    text-align: left;
    padding: 0.5rem 0rem 0.5rem 1.5rem !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #808080 !important;
}

.borderBottom {
    border-bottom: 2px solid #ddd;
}

.ui.table.plainTable tfoot tr th,
.ui.table tfoot tr:first-child>th:only-child {
    padding: 1rem 1.5rem 1rem 2rem;
}

.content.footer {
    margin: 0px !important;
    justify-content: center !important;
}

.content.footer a {
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold
}

.ui.list.scuf-list-wrapper .content {
    padding: 0px !important
}

.ui.list.scuf-list-wrapper .header {
    display: none
}
.mycart-loader {
    position: fixed !important;
}

.price-change-notification .title {
    margin-top: 6px;
}

.paddingZero  .ui.breadcrumb {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0.75rem;
}

.my-cart-heading.scuf-col.col-12 {
    padding: 2rem 0 1.25rem 0;
}

.cartListCard .ui.card {
    margin: 0;
}

.fullwidthRow .cart-list-panel {
    padding: 0 0.75rem 0;
}

.cartListContent .cart-gridcol.col-6 {
    padding: 0 0 1.25rem;
}

