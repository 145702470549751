.pull-right{float: right}
.smallBox{height: 40px;	width: 150px;	border: 1px solid #D0D0D0;
    padding:9px 20px;	background-color: #F0F0F0;font-size: 14px;	font-weight: bold;	line-height: 22px;
    margin-right: 10px;
    margin-bottom: 10px;}
    .cp-taxDetail .scuf-col{padding-bottom: 0px;}
    .cp-taxDetail  span{float: right; padding-right: 20px;text-align: right;}
    .extendedPrice {font-size: 12px;}
    .configureFeaturesLoader{
        left: 70%;
        top: -25%;
    }