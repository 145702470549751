/*************full width styles***************/
.fullwidthRow {
    margin: 0px !important;
    padding: 0px !important;
}

/*************secondary navbar styles***************/
.secondaryNav {
    background: #ffffff;
    padding: 10px 0px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px;
}

.secondaryNav .scuf-col {
    padding: 0px 12px !important;
    line-height: 30px;
}

.secondaryNav p,
.secondaryNav a {
    float: right;
}

/********dropdown styles********/
.ui.scuf-dropdown-wrapper {
    width: 100%;
}

/************checkbox styles********/
.ui.checkbox input ~ label,
.ui.checkbox input ~ label:hover {
    color: #404040 !important;
    font-size: 12px !important;
}

/******************form input styles***********/
.inputBoxWrapper .ui.single-input {
    width: 100%;
}

.inputBoxWrapper .ui.single-input .input-wrap {
    width: 100%;
}

.inputBoxWrapper .ui.single-input .input-wrap .input-box {
    width: 100%
}

.ui.single-input.input-example {
    width: 47%
}

.ui.single-input.input-example .input-wrap {
    width: 100%;
}

.ui.single-input.input-example .input-wrap .input-box {
    width: 100%
}

.emailConfir .ui.input-label .input-label-message {
    padding-top: 20px;
    padding-bottom: 20px;
}

.tabPaneStyles .ui.scuf-dropdown-wrapper {
    width: 47%;
}

.twoSelectBoxes .ui.scuf-dropdown-wrapper {
    width: 89% !important;
}

.linkBox .ui.single-input.input-example .input-wrap,
.linkBox .ui.single-input.input-example {
    width: 95%;
}

.ui.checkbox{
    width:100%;
    margin: 0px;
}
/************datepicker styles*********/
.overlay-position-wrap {
    width: 64%
}

.ui.date-picker-input {
    width: 100%
}

.ui.single-input.picker-input.has-icon.align-right {
    width: 100%;
}

.ui.single-input.picker-input.has-icon.align-right .input-wrap {
    width: 74% !important;
}

.ui.single-input.picker-input.has-icon.align-right .input-wrap .input-box {
    width: 100%
}

/***********choose file for upload po document styles*************/

.uploadGroup .ui.single-input {
    width: 47% !important;
}

.uploadGroup .ui.single-input .input-wrap {
    width: 100%;
}

.uploadGroup .ui.single-input .input-wrap .input-box {
    width: 100%
}

div.ui.pointing.secondary.ui.scuf-tab.menu .item {
    color: #808080;
    font-size: 14px !important;
}

div.ui.pointing.secondary.ui.scuf-tab.menu {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.single-input.has-icon.align-right .input-icon {
    color: #1792E5;
    font-weight: bold
}

/*******************page level utility styles****************/

.helpText {
    color: #808080;
    font-size: 12px;
}

.noMarginTop .input-label-message {
    margin-top: 0px !important
}

.noMarginTop.ui.single-input {
    width: 102%
}

.noMarginTop.ui.single-input .input-wrap {
    width: 47%
}

.noMarginTop.ui.single-input .input-wrap .input-box {
    width: 100%
}

.pull-right {
    float: right
}

.fullWidthButton {
    width: 100%
}

.pageHeading {
    font-weight: 300;
    text-align: center;
    color: #101010;
}

.paddRightZero {
    padding-right: 0px !important
}

#chooseFile {
    height: 48px !important;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
}

div.ui.input-label .input-label-message {
    font-size: 14px !important
}

.shipping_address {
    font-size: 14px;
    font-weight: 500;
    color: #101010;
    background: #F7F7F7;
    padding: 20px;
    border: solid 1px #c0c0c0;
}

.addressBody {
    font-size: 12px;
    color: #606060;
}

.fullWidthInput, .fullWidthInput .input-wrap, .fullWidthInput .input-wrap .input-box {
    width: 99% !important;
}

.alignpara {
    margin-top: 30px !important;
}
.ui.single-input.input-example.customWidth{width:95% !important;}

.alignPara {
    margin-top: 5px !important;
}

.shipping-billing-panel .emailConfir {
    padding-bottom:0px;
}
.account-info-text
{
font-size: 16px;
letter-spacing: 0.69px;
font-weight: 700;
font-style: normal;
}
.change-account--link {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.54px;
    color: #1792E5 !important;
}
.address-header-text {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: #606060;
}
.confirm-checkbox .ui.checkbox input ~ label, .confirm-checkbox .ui.checkbox input ~ label:hover {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #606060 !important;
}
.billing-page-subheading {
    font-size: 16px;
    font-weight: 800 !important;
    line-height: 1.5;
    color: #404040;
    padding-top: 12px;

}
.emailConfir .ui.input-label .input-label-message {
    font-weight: 800;
    line-height: 1.57;
    font-size: 14px;
}

.emailConfir div.ui.input-label .badge {
    margin-bottom: 27px !important;
}
.placeorder--btn {
    width: 100% !important;
    padding-left: 34px !important;
    padding-right: 34px !important;
}
.placeorder--div{
    border-top: 1px solid #d0d0d0;
    padding-top: 15px;
}
.shipping-billing-loader {
    position: fixed !important;
}
.accept-terms-check{
    padding-top: 10px !important;
}

/* New prototype changes */
.paymment-opts-button {
    margin-bottom: 20px;
}

.uploadGroup .browse-file-btn{
    margin-left: 20px;
}

.PO-datepicker .ui.single-input.has-icon.align-right .input-icon {
    color: #404040;
}
.noPaymentsSubText .title::after {
           content: '   No payment required for this order';
           color: rgb(112, 112, 112);
font-size: 13px;
font-style: italic;
font-weight: 500;
height: 20px;
letter-spacing: 0px;
line-height: 20px;
}

.email-label {
    font-size: 15px;
    font-weight: 600;
    margin-top: 8px;
    margin-left: 6px;
}

.shipping-billing-page .ui.breadcrumb {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0.75rem;
}

.shipping-billing-page-heading.scuf-col.col-12 {
    padding: 2rem 0 1.25rem 0;
}

.shipping-billing-panel .ui.card .content {
    padding: 1.25rem 0.5rem 1.25rem 0.5rem;
}

.shipping-billing-panel .address-info {
    padding: 0 0.75rem 1.25rem;
}

.shipping-billing-panel  .address-header-text {
    padding: 0 0.75rem 0.5rem 0.65rem;
}

.shipping-billing-panel .address-content {
    padding: 0 0.75rem 1.25rem 0.65rem;
}

.shipping-billing-panel .email-address {
    padding: 0 0.75rem 0;
}

.shipping-billing-panel .accordion.ui.fluid .title{

    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;

}

.shipping-billing-panel .accordion.ui.fluid .active.content{

    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;

}
.shipping-billing-panel .ui.accordion:not(.styled) .title ~ .content:not(.ui) {
    padding: 0 0 20px 0;
}
.shipping-billing-panel .shipping-billing-card-panel {
    padding: 0 0.5rem 1.25rem 0.75rem;
}

.shipping-billing-panel .payment-panel-padding  {
    padding: 0 20px 12px 20px;
}

.shipping-billing-panel .paymetric-payment-padding {
    padding: 0;
}

.shipping-billing-panel .payment-panel {
    padding-bottom: 20px;
}

.shipping-billing-panel div.ui.pointing.secondary.ui.scuf-tab.menu .item {
    padding: 0;
}

.shipping-billing-panel div.ui.pointing.secondary.ui.scuf-tab.menu {
    margin-bottom: 20px;
}