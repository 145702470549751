/************order summary styles****************/
.checkoutTable.ui.table tr td {
    text-align: right;
    letter-spacing: 0.54px;
    border-bottom: 1px solid #D0D0D0;
    padding:10px 0px;
    font-size: 14px !important;
}

.checkoutTable.ui.table tr td:first-child {
    text-align: left;
    color: #404040;
}

.checkoutTable.ui.table {
    border: none
}

.checkoutTable.ui.striped.table>tr:nth-child(2n),
.checkoutTable.ui.striped.table tbody tr:nth-child(2n) {
    background: #fff
}

.checkoutTable.ui.table tr:last-child td {
    border-bottom: none
}
.order-summary-title{
    font-weight: bold !important;
}
.tax-amount {
    color: #404040 !important;
}
.sub-total-amount {
    color: #000000 !important;
    font-weight: 800 !important;
}
.grand-total-amount {
    color: #303030 !important;
    font-weight: 800 !important;
    letter-spacing: 0.62px !important;
}

.no-bottom-border {
    border-bottom: none !important;
  }

  .order-summary-panel .ui.card .header {
    padding: 20px 20px 0 20px;
}

.order-summary-panel .ui.card .content {
    padding: 10px 20px 20px 20px;
}

.order-summary-panel .placeorder--div {
    padding-top: 20px;
}
