#banner_item_detail {
    background-color: transparent !important;
    border: none !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
}

#banner_item_detail h1 {

    color: #404040;
    font-size: 36px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    margin-left: 1.5rem;
    padding-left: 0px;
    -ms-flex-pack: center;
    font-weight: 900;
    line-height: 28px;
}

#banner_item_detail h1 br {
    display: none;
}

.item-detail.img-full-width .image {
    margin: -6px 0px 0px;
    display: block;
    min-height: 188px;
}

@media all and (-ms-high-contrast: none) , ( -ms-high-contrast : active)
{
    #banner_item_detail h1 br {
                    display: block;
    }
}

@media ( min-width :500px) and screen {
    #banner_item_detail h1 {
                    font-size: 32px !important;
                    max-width: 80%;
    }
}

@media screen and (max-width:500px) {
    #banner_item_detail h1 {
                    font-size: 22px !important;
                    max-width: 80%;
                    height: 160px;
    }
}

#banner_item_detail h1 small {

    color: #1792E5;	font-size: 14px;
    margin-bottom: 5px;
    display:block;
    font-weight: normal;
}

.image {
    background: url(./images/new_b1.png) no-repeat;
    background-size: cover;
}






@media screen and (min-width:0\0) and (min-resolution: .001dpcm) {

#banner_item_detail h1{
    position:absolute;
    top:60px
}
}
