.fullwidthRow {
  margin: 0px !important;
  padding: 0px !important;
}

.secondaryNav {
  background: #ffffff;
  padding: 16px 0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px;
}

.secondaryNav .scuf-col {
  padding: 0px 12px !important;
  line-height: 30px;
}

.secondaryNav p,
.secondaryNav a {
  float: right;
}

.payment-label {
  font-weight: 600 !important;
  color: #808080 !important;
}

.pull-right {
  float: right;
}

.ui.scuf-dropdown-wrapper {
  width: 100%;
}

.helpText {
  color: #808080;
  font-size: 12px;
  padding-left: 10px;
}

.checkoutTable.ui.table tr td {
  text-align: right;
  border-bottom: 1px solid #d0d0d0;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.checkoutTable.ui.table tr td:first-child {
  text-align: left;
}

.checkoutTable.ui.table {
  border: none;
}

.checkoutTable.ui.striped.table > tr:nth-child(2n),
.checkoutTable.ui.striped.table tbody tr:nth-child(2n) {
  background: #fff;
}

.checkoutTable.ui.table tr:last-child td {
  border-bottom: none;
}

.fullWidthButton {
  width: 100%;
}

.overlay-position-wrap {
  width: 64%;
}

.paddingZero {
  padding: 0px !important;
}

.centerAlign {
  text-align: center;
}

.pageHeading {
  font-weight: 300;
  text-align: center;
  color: #101010;
}

.ro-checkbox.ui.checkbox {
  width: 35px;
  height: 25px;
}
.ro-terms-div {
  display: flex;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: -0.18px;
}
.ro-terms-div a {
  color: #1792e5 !important;
}
.tncError{
  padding: 0px;
}
#placeOrder-btn{
  margin-top: 1.25rem;
}
h3 {
  font-size: 18px;
  font-weight: bold !important;
}

.smallHeading {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

.paddingbottom {
  padding-bottom: 0px !important;
}

.address {
  font-size: 14px;
  font-weight: 500;
  color: #303030;
}

.helpTextItalic {
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  color: #606060;
}

.address .helpTextItalic {
  padding-top: 3vh;
}

.ui.table.plainTable tr td,
.ui.table.plainTable tr th {
  padding: 0.2rem 0rem 0.2rem 0;
}

.plainTable {
  border: none !important;
}

.ui.striped.table.plainTable tbody tr:nth-child(2n) {
  background: #fff;
}

.ui.table.plainTable tr td:first-child {
  font-size: 14px;
  color: #707070;
  line-height: normal;
  font-weight: 600;
  font-style: normal;
}

.ui.table.plainTable tr td:last-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 2.14;
  color: #303030;
  font-style: normal;
}

.capitalText {
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
  color: #808080;
}

.cardMarginBottom {
  margin-bottom: 20px !important;
}
.ui.table.myCartTable.plainTable thead tr th,
.ui.table.apu-myCartTable.plainTable thead tr th {
  border-top: 1px solid #e0e0e0 !important;
  border-bottom: 2px solid #e0e0e0 !important;
  padding: 0.5rem 0rem 0.5rem 0;
  height: auto;
}
.ui.table.myCartTable.plainTable tbody tr td,
.ui.table.apu-myCartTable.plainTable tbody tr td {
  color: #404040;
}

.ui.table.myCartTable.plainTable,
.ui.table.apu-myCartTable.plainTable {
  margin: 0rem;
}
.amount {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: left;
  color: #808080;
  line-height: normal;
  margin: 0 0 5px;
  padding-left: 25px
}

.amount-value {
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 5px 0px 5px 25px;
  color: #606060;
}

.quantity {
  font-size: 12px;
  text-transform: capitalize;
  text-align: left;
  color: #a0a0a0;
  font-weight: 700;
}
.quantities {
  border-right: 1px solid #b0b0b0;
  padding-right: 24px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 20px;
}
.card-details-info .content.cartListContent .scuf-grid.col-12.totalprice {
  border-top: 2px solid #e0e0e0;
  padding-top: 20px;
}
.total-price-text {
  font-size: 14px;
  line-height: 1.57;
  font-weight: 500;
  font-style: normal;
}
.total-price-amount {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.25px;
}

.icon-hover{
  cursor: pointer;
}
.ship-bill-info-text {
  font-weight: 600 !important;
  font-style: normal;
  line-height: normal;
  color: #404040;
}

.ro-heading-text {
  font-size: 14px;
  color: #707070;
  line-height: normal;
  font-weight: 600;
  font-style: normal;
}

.payment-method-info-label{
  font-weight: 600 !important;
  color:  #404040 !important;
}
.ro-hr-line {
  background-color: #D0D0D0;
  height: 2px;
  border: 0;
}
.ro-bill-ship-info-card.ui.card > .content {
  padding: 20px;
}

.delete-pop-up {
  position: relative;
  z-index: 1000 !important;
}
.product-width{
  width: 70% !important;
}

.dap-modal-header {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.31;
  color: #404040;
}
.dap-modal-table.ui.table thead tr th {
  font-size: 12px;
  font-style: normal;
  line-height: 1.67;
  color: #606060;
}
.dap-modal-table.ui.table thead tr th,
.dap-modal-table.ui.table tbody tr td {
  border-bottom: 2px solid #d0d0d0 !important;
}

.dap-modal-table.ui.table tbody tr td:first-child span{
  padding-left: 10px;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  line-height: 1.5;
  text-transform: uppercase;
  color: #404040;
}
.dap-modal-table.ui.table tbody tr td:nth-child(2),
.dap-modal-table.ui.table tbody tr td:nth-child(3),
.dap-modal-table.ui.table tbody tr td:nth-child(4) {
  /** Quantity, Delivery Type Column **/
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.57;
  color: #303030;
}
.dav-product-icon {
  display: flex;
}
.dav-product-icon > i {
  width: 40px;
  height: 34px;
  background-size: 91%;
  margin-left: 0px;
}
.review-order-page .ui.breadcrumb {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0.75rem;
}

.review-order-page .heading-padding {
  padding: 0;
}

.review-order-page .pageHeading {
   padding-top: 32px;
   padding-bottom: 20px;
}
.review-order-page .bill-heading {
  padding-bottom: 20px;
}

.review-order-page  .review-order-info {
  padding:  0 0.5rem 1.25rem 0.75rem;
}

.review-order-page  .card-details-info {
  padding:  0 0.5rem 0 0.75rem;
}
.review-order-page .bill-info {
  padding: 0 10px 0 0;
}
.review-order-page .ship-info {
  padding: 0 0px 0 10px;
}
.order-summary-panel .terms-check {
  padding-bottom: 0;
}
.review-order-page .review-payment-info  {
  padding: 0 0 0 10px;
}
.ui.modal.delete-pop-up {
  padding: 1.25rem;
}

.ui.modal.delete-pop-up .actions {
  padding: 1.25rem 0 0;
}

.delete-pop-up .delete_product_list {
  padding: 0 .75rem 0;
}
.delete-pop-up .ui.table.dap-modal-table tr td {
  padding: 1.25rem 2rem 1.25rem 1.25rem;
}