.modal .header p span {
    color: #303030;
    font-size: 16px;
    font-weight: bold;
}

.ui .error-message {
    margin-bottom: 0px
}

div.ui.pointing.secondary.ui.scuf-tab.menu {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

#inputBoxes .scuf-grid.col-12 .slider-controls-plus {
    display: none
}

#inputBoxes .scuf-grid.col-12:last-child .slider-controls-plus {
    display: block
}

.box {
    padding: 20px !important
}

.ui.ordered.steps {
    width: 100%
}

.marginBottom15 {
    margin-bottom: 15px !important
}

/**********step2 specific CSS*******************/

.ui.steps .step.completed>.icon:before,
.ui.ordered.steps .step.completed:before {
    color: #21BA45 !important;
}

.ui.selection.dropdown {
    height: 44px !important;
    padding: 9px 12px;
}

.ui.striped.table {
    border: none
}

.pull-right {
    float: right;
}

/**********step3 specific CSS*******************/

.steps.ui .link.item .title {
    font-size: 18px;
}

.ui.striped.table.cp-bulk-stepThreeTable>tr:nth-child(2n),
.ui.striped.table.cp-bulk-stepThreeTable tbody tr:nth-child(2n) {
    background-color: #ffffff;
}

.helpText {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: normal;
    color: #808080;
}

.displayBlock {
    display: block;
}

.rightBlock .col-12 {
    padding: 0px
}

.rightBlock p {
    text-align: right
}

.paddingBottom {
    padding-bottom: 0px
}

/***********************stepper CSS*****************/

.steps.ui .link.item {
    padding: 10px !important;
    /* padding: 1rem !important; */
}

.link.item.step.completed:before {
    content: '\ea56';
    font-family: 'Honeywell-Icons', sans-serif !important;
    padding: 6px;
    font-size: 35px;
}

.h-icon.common.sc-bdVaJa.dnABsV {
    margin-right: 1rem !important;
    transform: rotate(180deg);
    padding-left: 0px !important;
    padding-right: 0rem !important;
    float: left;
}

.configure-apu-modal-content .stepperClass .sc-bdVaJa.h-icon.common,
.configure-wireless-modal .stepperClass .sc-bdVaJa.h-icon.common,
.configure-product-modal  .stepperClass .sc-bdVaJa.h-icon.common{
    font-size: 28px !important;
    padding-left: 3px;
}

.configure-apu-modal-content .accept-terms-check {
    margin-top: 15px;
}

.configure-wireless-modal .accept-terms-check {
    margin-top: 10px;
}
/**********************browse files styles***************************/
.file-drop-div {
    background-color: #ffffff;
}
.ui.file-drop.populated .browse-files {
    display: none
}
/** Fixing the dropdown size for laptop, to avoid movements */
@media screen and (max-width: 1440px) {
    .file-drop-div.ui.file-drop .drop-zone {
        min-height: 100px;
    }
}
.ui.file-drop .success-icon {
    font-size: 0.8rem;
    color: #21BA45 !important;
    border-radius: 30px;
    border: 2px solid #21ba45;
    padding: 7px;
    margin-bottom: 10px;
    font-weight: bold;
}
.bc-config-link {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.57;
    color: #303030;
}
.bc-download-link, .bc-download-link:hover {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: #1792E5 !important;
    padding-left: 15px;
}
.bc-config-temp-link {
    padding-top: 1rem !important;
}
/** Step-2 Confirm Configuration CSS Rules **/
.uploadedLabel {
    font-size: 14px;
    font-weight: 800;
    font-style: normal;
    line-height: 1.57;
    color: #303030;
}
.uploadedValue {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.1;
    color: #303030;
}
.cp-bulk-error-msg {
    color:#be271c;
    font-weight: 600;
    font-size: .75rem;
    line-height: 1.25rem;
    word-wrap: break-word;
    margin-top: 10px;
}
.cp-conf-sub-heading {
    margin-bottom: 0em;
}
/** Setting the modal scrolling content size for laptop **/
@media screen and (max-width: 1440px) {
    /** Setting scrolling content lesser to make the edges of the modal visible **/
    .configure-product-modal.ui.modal .scrolling.content {
        max-height: calc(60vh);
    }
}